@import "../../variables.scss";

@mixin underlineText {
    text-decoration: underline;
    text-underline-offset: 9px;
    text-decoration-color: $nzbrokers-red;
    text-decoration-thickness: 2px;
    border-color: transparent;
}

.policy-error {
    padding-top: 3rem;

    .no-results {
        h3 {
            color: $nzbrokers-blue;
        }
    }
}

.sticky {
    position: sticky;
    z-index: 10;
    top: 0;
}

#policy-tabs {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 0;


    .table {
        tr.selected {
            background-color: $nzbrokers-sky-blue;
        }
    }

    .tab-content {
        padding: 0rem 2rem;
    }


    .nav-tabs {
        background: white;
        border-bottom: none;
        padding: 0.3rem 2rem;
        margin-bottom: 1rem;
        margin-top: 1px;
        box-shadow: 0 4px 2px -2px #ccc;

        :first-child {
            .nav-link {
                padding-left: 0;
            }
        }

        .nav-item {
            > a {
                font-weight: bold;
                color: $nzbrokers-blue;
                text-decoration: none;
                cursor: pointer;
                font-size: 1.25rem;


                &:hover {
                    @include underlineText;
                    text-decoration-color: $nzbrokers-grey;
                }

                &.active {
                    @include underlineText;
                    text-decoration-color: $nzbrokers-red;
                }
            }
        }
    }
}

.multi-risk-bar {
    position: sticky;
    width: 100%;
    bottom: 0;
    background-color: $nzbrokers-navy-transparent;
    border-bottom: 8px solid $nzbrokers-sky-blue;
    padding: 0.75rem;

    .container {
        display: flex;
        justify-content: flex-end;
    }
}

.policy-row {
    padding: 0.5rem 0;

    &.header {
        border-top: 1px solid;
        padding-top: 1rem;
        margin-top: 1rem;
    }

    &.pch {
        border: 1px solid dodgerblue;
        margin-top: 1rem;
        margin-bottom: 1rem;
        background-color: aliceblue;
    }



    .display.heading {
        color: $nzbrokers-blue;
        font-size: 1.3rem;
        font-weight: 600;

        u {
            text-decoration: none;
        }

        b {
            font-weight: unset;
        }
    }

    .display.sub-heading {
        color: $nzbrokers-blue;
        font-size: 1.1rem;
        font-weight: 600;

        u {
            text-decoration: none;
        }

        b {
            font-weight: unset;
        }
    }


    .policy-col {
        display: flex;

        .title {
            font-weight: 200;

            .required {
                padding-left: 2px;
                color: $nzbrokers-red;
            }
        }

        .value {
            font-weight: 600;

            textarea {
                height: 100px;
                width: 100%;
                padding: 0.25rem 0.5rem;
            }

            select {
                width: 100%;
            }

            [type="radio"]:checked + label:after,
            [type="radio"]:not(:checked) + label:after {
                background-color: dimgrey;
            }


            .option {
                display: inline-block;
                padding-right: 1rem;
            }
        }
    }
}

.pch-block {
    border: gold 1px solid;
    background: lightgoldenrodyellow;

    .display.heading{
        font-size: 1rem;
    }
}
