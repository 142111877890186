
.search-page {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    height: calc(100vh - 66px);

    .search-top {
        flex: 1;
        overflow: auto;
    }

    .search-bottom {
        flex-shrink: 0;
    }
}
