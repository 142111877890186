@import '../../variables.scss';

.spinner {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    margin: auto;
    pointer-events: none;
    cursor: default;
    z-index: 999999;

    &.absolute {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
    }

    &.dim {
        background: rgba(0, 0, 0, 0.5);
    }

    .lds-ripple {
        display: inline-block;
        position: relative;
        width: 80px;
        height: 80px;
    }

    .lds-ripple div {
        position: absolute;
        border: 4px solid $nzbrokers-blue;
        opacity: 1;
        border-radius: 50%;
        animation: lds-ripple 1.5s cubic-bezier(0, 0.2, 0.8, 1) infinite;
    }

    &.light {
        .lds-ripple div {
            border: 4px solid $nzbrokers-sky-blue;
        }
    }

    .lds-ripple div:nth-child(2) {
        animation-delay: -0.5s;
    }
}

@keyframes lds-ripple {
    0% {
        top: 36px;
        left: 36px;
        width: 0;
        height: 0;
        opacity: 1;
    }

    100% {
        top: 0px;
        left: 0px;
        width: 72px;
        height: 72px;
        opacity: 0;
    }
}
