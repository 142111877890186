.cover-reference-view {

    .header-info {
        margin-top: 3rem;
        margin-bottom: 3rem;

        .header-item {
            display: flex;
            justify-content: space-between;

            .title {
                font-size: 1.25rem;
                font-weight: 300;
                display: inline-block;
            }

            .value {
                font-size: 1.1rem;
                display: inline-block;
                font-weight: 500;
            }
        }
    }

    .detail {
        margin-bottom: 2rem;
    }
}
