@import "../../variables.scss";

.policy-details-header {
    background-color: $nzbrokers-navy;
    display: flex;
    flex-direction: row;
    padding: 0.75rem;

    .segment {
        color: white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        border-right: 1px solid rgba(255, 255, 255, 0.5);
        flex: 1;
        padding: 0.25rem 0.75rem;

        .title {
            font-size: 0.9rem;
            font-weight: 200;
        }

        .value {
            font-size: 1.2rem;
            font-weight: 400;
            margin-top: -0.25rem;
            min-width: 6rem;
        }

        &:last-child {
            border-right: none;
        }
    }

    .back-btn {
        max-width: 150px;
        position: sticky;
        right: -11px;
        background: #092D48;
        margin: -0.75rem 0px -0.75rem 0;
        button {
            background-color: #CA4B3A;
        }
    }
}
