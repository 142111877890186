@import "../../variables.scss";

.login {
    display: flex;
    flex-direction: row;
    flex: 1;

    .login-left {
        flex: 0.25;
        background-color: $nzbrokers-gold;
    }

    .login-right {
        flex: 0.75;
        display: flex;
        flex-direction: column;

        .login-top {
            background-color: $nzbrokers-navy;
            flex: 1;
            color: white;
            padding: 2rem;

            .title {
                margin-bottom: 2rem;
            }

            .btn-auth-action {
                background-color: $nzbrokers-red;
                padding: 0.5rem 2rem;
                color: white;
                outline: none;
                cursor: pointer;
                border: none;
            }
        }

        .login-bottom {
            display: flex;
            flex-direction: row;
            height: 300px;

            .login-bottom-middle {
                background-color: $nzbrokers-sky-blue;
                flex: 0.75;
            }

            .login-bottom-right {
                flex: 0.25;
                background-color: $nzbrokers-red;
            }
        }
    }
}
