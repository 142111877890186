@import "../../../variables.scss";

.results-table {
    height: 100%;
    display: flex;
    flex-direction: column;

    .headerRow {
        background-color: $nzbrokers-blue;
        text-transform: none;

        .ReactVirtualized__Table__headerColumn {
            color: white;
            background-color: $nzbrokers-blue;
            border-color: $nzbrokers-blue;
            font-weight: 500;
            font-size: 1.1rem;
        }
    }

    .evenRow {
        background-color: #F4F4F4;
    }

    .ReactVirtualized__Grid {
        will-change: auto;
        outline: none;
    }

    .ReactVirtualized__Table__row {
        font-size: 15px;
        line-height: 1.2;
        font-weight: unset;
        cursor: pointer;
        outline: none;

        &:hover {
            background: rgba(36, 58, 90, 0.2);
        }
    }

    .selectedPolicy {
        background-color: $nzbrokers-sky-blue;
        font-weight: 600;

        &:hover {
            background: $nzbrokers-sky-blue;
        }
    }
}