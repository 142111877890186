@import "../../variables.scss";


.inquiry-container {
    width: 100%;
    background-color: $nzbrokers-navy-transparent;
    border-bottom: 8px solid $nzbrokers-sky-blue;
    padding: 0.75rem;

    .container {
        display: flex;
        flex-direction: row-reverse;
    }

    .inquiry-btn {
        background-color: $nzbrokers-blue;
    }
}

.search-results {
    padding: 3rem 2rem;
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;

    .no-results {
        h3 {
            color: $nzbrokers-blue;
        }
    }

    mark, .mark {
        padding: 0.2em;
        background-color: #FFFF00;
    }

    .collapsable {
        display: flex;
        flex-direction: column;
        border-top: 1px solid #000;

        &:last-child {
            border-bottom: 1px solid #000;
        }

        .header {
            font-size: 1.5rem;
            font-weight: 400;
            padding: 0.5rem 0rem;
            cursor: pointer;
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .header-text {
                color: $nzbrokers-blue;
                -webkit-user-select: none; /* Safari */
                -moz-user-select: none; /* Firefox */
                -ms-user-select: none; /* IE10+/Edge */
                user-select: none; /* Standard */
            }

            .header-icon {
                color: $nzbrokers-blue;
            }
        }
    }
}
