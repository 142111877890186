a.navbar-brand {
    white-space: normal;
    text-align: center;
    word-break: break-all;
}

html {
    font-size: 14px;
}

@media (min-width: 768px) {
    html {
        font-size: 16px;
    }
}

.box-shadow {
    box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}


.navbar {
    background-color: #176a99;

    .nav-signout {
        background-color: transparent;
        border: 0;
        outline: none;
        padding: 0 8px;

        &:active {
            border: 0;
            outline: none;
            padding: 0 8px;
        }

        &:hover {
            border: 0;
            padding: 0 8px;
        }
    }

    .navbar-nav {
        .active-nav-item {
            font-weight: bold;
        }
    }
}

.navbar-light {
    .navbar-nav {
        .nav-item {
            a {
                color: white;
                padding: 0 .5rem;

                &.active-nav-item {
                    font-weight: bold;
                }

                &:hover {
                    text-decoration: none;
                }
            }
        }
    }
}

.logo {
    height: 2.5rem;
}

.username {
    color: white;
    padding-right: 1rem;
}
