$nzbrokers-grey: #3F3E3E;
$nzbrokers-blue: #176A99;
$nzbrokers-navy: #092D48;
$nzbrokers-red: #CA4B3A;
$nzbrokers-sky-blue: #90C5D0;
$nzbrokers-gold: #E9B530;

$nzbrokers-navy-transparent: rgba(9,45,72,0.93);


$smallPadding: 3px;
$boundryPadding: 9px;
$internalPadding: 6px;

