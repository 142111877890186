@import "../../variables.scss";

.search-control {
    display: flex;
    flex-direction: row;
    color: white;
    transition: all linear 1s;

    .group-filter {
        display: flex;
        flex-direction: row;
        margin-left: auto;
        color: white;
        padding-bottom: 1.5rem;
        align-items: center;


        label {
            padding-left: 1rem;
        }

        span {
            margin-right: 0.5rem;
        }
    }


    .left-buffer {
        background-color: $nzbrokers-gold;
        flex: 0.05;
    }

    .cover-search-container {
        display: flex;
        flex-direction: column;
        flex: 0.65;
        background-color: $nzbrokers-navy;
        padding: 2rem;

        .search-title-container {
            display: flex;
            flex-direction: row;
            justify-content: space-between;


            h1 {
                font-weight: 600;
                margin-bottom: 1.5rem;
            }
        }

        .search-prompt-container {
            font-size: 0.8rem;
            padding-bottom: 0.5rem;
        }

        .search-inputs-container {
            display: flex;
            flex-direction: row;
            padding-bottom: 1rem;

            .searchbox {
                height: 2rem;
                display: flex;
                flex: 1;
                max-width: 480px;
            }

            .checkbox-filter {
                display: flex;
                margin-left: 2rem;
                align-items: center;

                span {
                    padding-left: 1rem;
                    font-size: 0.8rem;
                    cursor: pointer;
                }

                .checkmark:after {
                    border-color: $nzbrokers-navy;
                }
            }
        }

        .filters-container {
            display: flex;
            flex-direction: row;

            .search-btn-container {
                margin-left: auto;
            }

            .filters {
                display: flex;
                flex-direction: row;
                align-items: center;

                .filters-title {
                    font-weight: 500;
                    font-size: 1.25rem;
                }
            }

            .option {
                padding-left: 2rem;

                input {
                    margin-right: 1rem;
                }
            }

            button {
                background-color: $nzbrokers-red;
            }
        }
    }

    .rpt-search-container {
        display: flex;
        flex-direction: column;
        flex: 0.3;
        background-color: $nzbrokers-sky-blue;
        font-size: 1rem;

        .top-buffer {
            flex: 0.3;
        }

        .rpt-search {
            padding: 1rem 2rem 2rem 2rem;
            background-color: $nzbrokers-grey;
            flex: 0.7;
            display: flex;
            flex-direction: column;

            .search-prompt-container {
                font-size: 0.8rem;
                padding-bottom: 0.5rem;
            }

            input {
                display: flex;
                height: 2rem;
                width: 100%;
            }

            .search-btn-container {
                display: flex;
                flex-flow: row-reverse;
                margin-top: 1rem;
                flex: 1;

                button {
                    height: auto;
                    display: flex;
                    align-self: flex-end;
                    background-color: $nzbrokers-red;
                }
            }
        }
    }
}
