@import "variables.scss";
/* Provide sufficient contrast against white background */
a {
    color: #0366d6;
}

b, strong {
    font-weight: bold;
}

code {
    color: #E01A76;
}

.btn {
    padding: 0.5rem 2rem;
    color: white;
    font-size: 1.25rem;
    outline: none;
    cursor: pointer;
    border: none;
    border-radius: initial;
}

button {
    padding: 0;
    outline: 0;
    border: 0;
    margin: 0 0.25rem;

    &:disabled {
        opacity: 0.5;
    }
}

html, body, #root {
    height: 100%;
    font-family: Calibri,'Open Sans', sans-serif;
}

#root {
    display: flex;
    flex-direction: column;

    .addinsight {
        display: flex;
        flex-direction: column;
        height: auto;
        flex: 1;
    }
}

.page-content {
    padding-top: 3rem;
    padding-bottom: 3rem;
}

.page-title {
    font-weight: 300;
    margin-bottom: 1rem;
}

.table {
    thead {
        background-color: $nzbrokers-blue;
        color: white;
    }

    tbody {
        tr:nth-child(odd) {
            background: white;
        }

        tr:nth-child(even) {
            background: #E9E9E9;
        }
    }
}

.cursor-pointer {
    cursor: pointer;
}

@media (min-width: 1400px) {
    .container, .container-sm, .container-md, .container-lg, .container-xl {
        max-width: 1746px;
    }
}


[type="radio"]:checked,
[type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}

label {
    margin: 0;
}

[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label {
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
}

[type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 18px;
    height: 18px;
    border: 1px solid #ddd;
    border-radius: 100%;
}

[type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
    content: '';
    width: 12px;
    height: 12px;
    background: #fff;
    position: absolute;
    top: 3px;
    left: 3px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

[type="radio"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}

[type="radio"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}


.radio-right {
    margin-right: 1.5rem;

    [type="radio"]:checked + label:before,
    [type="radio"]:not(:checked) + label:before {
        right: -24px;
        left: initial;
    }

    [type="radio"]:checked + label,
    [type="radio"]:not(:checked) + label {
        padding-left: 0px;
    }

    [type="radio"]:checked + label:after,
    [type="radio"]:not(:checked) + label:after {
        right: -21px;
        background-color: dimgrey;
        left: initial;
    }
}

.skeleton-effect-fade {
    animation: skeleton-effect-fade 3s infinite;
}

@keyframes skeleton-effect-fade {
    0% {
        opacity: 0.8;
    }

    50% {
        opacity: 0.5;
    }

    100% {
        opacity: 0.8;
    }
}
