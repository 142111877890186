@import "../../variables.scss";

.report-parser {
    .navigation {
        background-color: $nzbrokers-navy;
        padding: 1rem;
        display: flex;
        flex-direction: row;

        .container {
            display: flex;
            flex-direction: row;
            align-items: center;

            input {
                font-size: 1.5rem;
                font-weight: 300;
                height: 3rem;
                width: 100px;
                margin: 0 0.25rem;
                text-align: center
            }

            .goto-input {
                margin-left: 2rem;
            }

            .goto-btn {
                background-color: $nzbrokers-red;
                padding: 0.5rem 3rem;
            }


            .right {
                margin-left: auto;
            }

            .back-btn {
                background-color: $nzbrokers-red;
                padding: 0.5rem 3rem;
            }

            .fit-to-page {
                background-color: $nzbrokers-sky-blue;
                height: 46px;
                width: 3rem;
                padding: 0.4rem;
                margin-right: 1rem;

                svg {
                    height: 100%;
                    width: 100%;
                }
            }
        }
    }


    .report {
        margin: 1rem;
        position: relative;

        pre {
            display: unset;
            font-size: 1.1rem;
            margin: 0;
            padding: 0;
            overflow: unset;
            font-family: "Times New Roman", Times, serif;
        }
    }
}
