/*Custom Check Box adapted from: 
https://www.w3schools.com/howto/howto_css_custom_checkbox.asp
*/
.custom-checkbox-container {
    display: inline-grid;
    width: 24px;
    height: 24px;

    .custom-checkbox {
        display: block;
        position: relative;
        padding-left: 25px;
        margin-bottom: 25px;
        cursor: pointer;
        font-size: 22px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        /* Hide the browser's default checkbox */
        input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
        }

        .checkmark {
            position: absolute;
            top: 0;
            left: 0;
            height: 25px;
            width: 25px;
            background-color: transparent;
            border: 2px solid #fff;
            transition: all 0.2s ease;

            &.disabled {
                cursor: not-allowed;
            }
        }
        /* On mouse-over, add background color */
        &:hover input ~ .checkmark {
        }
        /* When the checkbox is checked, add background */
        input:checked ~ .checkmark {
            background-color: #fff;
            border: solid #fff;
        }
        /* Create the checkmark/indicator (hidden when not checked) */
        .checkmark:after {
            content: "";
            position: absolute;
            display: none;
        }
        /* Show the checkmark when checked */
        input:checked ~ .checkmark:after {
            display: block;
        }
        /* Style the checkmark/indicator */
        .checkmark:after {
            left: 38%;
            width: 7px;
            height: 16px;
            border: solid #000;
            border-width: 0 1.5px 1.5px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
        }
    }
}